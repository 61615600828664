import * as React from 'react'
import PropTypes from 'prop-types'

const Layout = ({ location, children }) => {
    const childrenWithProps = React.Children.map(children, (child) => {
        return React.cloneElement(child, {
            postInfo: {
                slug: location.pathname,
                canonical: location.pathname
            }
        })
    })

    return <>{childrenWithProps}</>
}

Layout.propTypes = {
    /**
     * Page location object
     */
    location: PropTypes.object.isRequired,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

export default Layout
